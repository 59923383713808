









import { Component, Vue } from 'vue-property-decorator'

import { Project } from '@/model/Project'
import NewProject from '../../components/core/project/NewProject.vue'
import router from '@/router'
import store from '@/store'

@Component({
  components: {
    NewProject,
  },
})
export default class ViewNewProject extends Vue {
  project: Project = {}
  user: Project = {}

  goBack = () => {
    router.back()
  }

  addProject = (project: Project) => {
    store
      .dispatch('addProject', {
        project: { ...project, main: false },
        userId: store.getters.loggedUser.id,
      })
      .then(json => {
        console.log(json)
        if (json.success) {
          router.push(`/project/${json.data.id}`)
        }
      })
  }
}
