


































import { Component, Vue } from 'vue-property-decorator'
import ProjectForm from './ProjectForm.vue'
import ProjectFormCreate from './ProjectFormCreate.vue'
import { Project } from '@/model/Project'
import { DigInputController } from '@digithia/input'

@Component({
  components: { ProjectForm, ProjectFormCreate },
})
export default class NewProject extends Vue {
  project: Project = {}

  updateProjectField({ field, value }: { field: keyof Project; value: any }) {
    this.project[field] = value
  }

  cancel() {
    this.$emit('cancel', this.project)
  }

  validate() {
    if (!DigInputController.checkInputsValidity('.new-project')) {
      this.$store.dispatch('toaster/toast', {
        type: 'warning',
        message: this.$t('invalidForm'),
      })
    } else {
      this.$emit('validate', this.project)
    }
  }
}
